import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const LinkMap = {
  TypeScript: "7e7290f699b",
  Reason: "7e7290f699b",
  Elm: "7e7290f699b",
  PureScript: "7e7290f699b",
  Babel: "7e7290f699b",
  SaaS: "7e7290f699b",
  "tsconfig.json": "7e7290f699b",

  "Type Annotations": "36dbc3f4b961",
  "Implicit Type Checking": "36dbc3f4b961",

  Any: "ddbe809af6b7",
  Unknown: "ddbe809af6b7",
  Interface: "ddbe809af6b7",
  Enum: "ddbe809af6b7",
  Tuples: "ddbe809af6b7",
  "type alias": "ddbe809af6b7",
  "Union Types": "ddbe809af6b7",
  "Intersection Types": "ddbe809af6b7",
  "Literal Types": "ddbe809af6b7",

  Class: "68f14d6f3510",
  Inheritance: "68f14d6f3510",
  Static: "68f14d6f3510",

  "Class Karşılaştırması": "25e1dac4de88",
  "Access Modifiers Karşılaştırması": "25e1dac4de88",
  Interfaces: "25e1dac4de88",
  "Immutable Data": "25e1dac4de88",
  Abstract: "25e1dac4de88",
  Generics: "25e1dac4de88",
  Reflection: "25e1dac4de88",
  Annotation: "25e1dac4de88",

  Vite: "45b8f438149a",
  "React TS": "45b8f438149a",
  "React TS1": "d6495bde1cb",
}

const storiesBasics = [
  {
    title: "TypeScript Nedir?",
    postId: "7e7290f699b",
  },
  {
    title: "TypeScript Basic Types",
    postId: "36dbc3f4b961",
  },
  {
    title: "TypeScript Special Types",
    postId: "ddbe809af6b7",
  },
  {
    title: "Java/C# Geliştiricilerine TypeScript-1",
    postId: "68f14d6f3510",
  },
  {
    title: "Java/C# Geliştiricilerine TypeScript-2",
    postId: "25e1dac4de88",
  },
]

const storiesReact = [
  {
    title: "Vite + React + TypeScript ile Counter Uygulaması",
    postId: "45b8f438149a",
  },
  {
    title:
      "React Projemizi JavaScript’ den TypeScript’ de Nasıl Dönüştürebiliriz ? -1",
    postId: "d6495bde1cb",
  },
  {
    title:
      "React Projemizi JavaScript’ den TypeScript’ de Nasıl Dönüştürebiliriz ? -2",
    postId: "527d86b04120",
  },
  {
    title:
      "React Projemizi JavaScript’ den TypeScript’ de Nasıl Dönüştürebiliriz ? -3",
    postId: "44cf8d1cd658",
  },
]

const storiesGroup = [
  {
    title: "TypeScript",
    storyPart: storiesBasics,
  },
  {
    title: "React ile TypeScript",
    storyPart: storiesReact,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "JS Typescript",
  path: "typescript-basics",
}

const TSBasicPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default TSBasicPage
